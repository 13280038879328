export const endpoint = 'https://graphql.bitquery.io';

export function GET_COIN_INFO(coinAddress, quoteCurrency) {
  if (quoteCurrency == "BNB")
  quoteCurrency = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
else
  quoteCurrency = "0xe9e7cea3dedca5984780bafc599bd69add087d56";
  const GET_COIN_INFO = `
  {
    ethereum(network: bsc) {
      dexTrades(
        options: {desc: ["block.height", "transaction.index"], limit: 1}
        exchangeAddress: {is: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"}
        baseCurrency: {is: "`+ coinAddress + `"}
        quoteCurrency: {is: "`+ quoteCurrency + `"}
      ) 
      {
        block {
          height
          timestamp {
            time(format: "%Y-%m-%d %H:%M:%S") 
          }
        }
        transaction {
          index
        }
        baseCurrency {
          name
          symbol
          decimals
        }
        quotePrice
      }
    }
  }
  `;
  return GET_COIN_INFO;
}
export function GET_COIN_BARS(coinAddress, from, to, resolution, quoteCurrency) {
  if (quoteCurrency == "BNB")
    quoteCurrency = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
  else
    quoteCurrency = "0xe9e7cea3dedca5984780bafc599bd69add087d56";

  if (allowedTokens(coinAddress) == true) {
    const GET_COIN_BARS = `
    {
      ethereum(network: bsc) {
        dexTrades(
          options: {asc: "timeInterval.minute"}
          date: {since: "`+ from + `", till: "` + to + `"}
          exchangeAddress: {is: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"}
          baseCurrency: {is: "`+ coinAddress + `"},
          quoteCurrency: {is: "`+ quoteCurrency + `"},
          tradeAmountUsd: {gt: 10}
        ) 
        {
          timeInterval {
            minute(count: `+ resolution + `, format: "%Y-%m-%dT%H:%M:%SZ")  
          }
          volume: quoteAmount
          high: quotePrice(calculate: maximum)
          low: quotePrice(calculate: minimum)
          open: minimum(of: block, get: quote_price)
          close: maximum(of: block, get: quote_price) 
        }
      }
    }
    `;
    return GET_COIN_BARS;
  }
}

export function GET_BNB_BARS(from, to, resolution) {
  const GET_BNB_BARS = `
{
  ethereum(network: bsc) {
    dexTrades(
      options: {asc: "timeInterval.minute"}
      date: {since: "`+ from + `", till: "` + to + `"}
      exchangeAddress: {is: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"}
      baseCurrency: {is: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"},
      quoteCurrency: {is: "0xe9e7cea3dedca5984780bafc599bd69add087d56"},
      tradeAmountUsd: {gt: 10}
    ) 
    {
      timeInterval {
        minute(count: `+ resolution + `, format: "%Y-%m-%dT%H:%M:%SZ")  
      }
      volume: quoteAmount
      high: quotePrice(calculate: maximum)
      low: quotePrice(calculate: minimum)
      open: minimum(of: block, get: quote_price)
      close: maximum(of: block, get: quote_price) 
    }
  }
}
`;
  return GET_BNB_BARS;
}
function allowedTokens(tokenAddress) {
  let bool = false;
  const allowedTokens = [
    {
      tokenAddress: "0x5af2fe41bd068e5469b69bfa5a4dbb616d6ab25c",
      symbol: "GSI"
    },
    {
      tokenAddress: "0xf2a170546890fc411fde171bb6be46a5a73f66c2",
      symbol: "FCO"
    },
    {
      tokenAddress: "0xbf96a921a95165034d7c6469b0c8670e81c654eb",
      symbol: "DMN"
    },
    {
      tokenAddress: "0x4bbd279fc77f11a5738e137723585c7c77afb267",
      symbol: "TKREV"
    }];

  allowedTokens.forEach(token => {
    if ((token["tokenAddress"].toLowerCase()).localeCompare(tokenAddress) == 0) {
      bool = true;
    }
  });
  return bool;
}

export function fixDates(fromDate, toDate, resolution, from) {
  //from date
  fromDate.setMonth(fromDate.getMonth() + 1);
  if (resolution === '15' || resolution === '30' || resolution === '60') {
    fromDate.setMonth(fromDate.getMonth() - 1);
    from = from - 2629743; // 1 month
  }
  if (fromDate.getMonth() == 0)
    fromDate.setMonth(fromDate.getMonth() - 1);

  if (fromDate.getDate() > 28)
    fromDate.setDate(fromDate.getDate() - 4);


  //to date
  toDate.setMonth(toDate.getMonth() + 1);
  if (toDate.getMonth() == 0)
    toDate.setMonth(toDate.getMonth() + 1);

  if (toDate.getDate() > 28)
    toDate.setDate(toDate.getDate() - 4);


  return { fromDate, toDate, from };

}