import axios from 'axios';
import * as Bitquery from './bitquery';

const lastBarsCache = new Map();
const configurationData = {
    supported_resolutions: [/*'1','5',*/'15', '30', '60', '1D' /*'1W', '1M'*/],
    chartTypes: ['heikin Ashi']
};
        //set heikin candles
        let sessionTradingViewValuesForHeikinCandles =localStorage.getItem('tradingview.chartproperties.mainSeriesProperties');
        if(sessionTradingViewValuesForHeikinCandles != null){
        sessionTradingViewValuesForHeikinCandles = sessionTradingViewValuesForHeikinCandles.replace('"style":1', '"style":8');
        localStorage.setItem('tradingview.chartproperties.mainSeriesProperties',sessionTradingViewValuesForHeikinCandles);
        }

export default {
    // This method is used by the Charting Library to get a configuration of your datafeed 
    // (e.g. supported resolutions, exchanges and so on)
    onReady: (callback) => {
        console.log('[onReady]: Method called!!');
        setTimeout(() => callback(configurationData));
        let myiFrame = document.querySelector("iframe");
        let doc = myiFrame.contentDocument;
        doc.head.innerHTML = doc.head.innerHTML + '<style>.group-3uonVBsm:nth-of-type(1),.group-3uonVBsm:nth-of-type(8),.group-3uonVBsm:nth-of-type(4) {display: none !important;}</style>';

    },
    // This method is used by the library to retrieve information about a specific symbol 
    // (exchange, price scale, full symbol etc.).
    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        console.log('[resolveSymbol]: Method called!!');
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tokenAddress = urlParams.get('address')
        const quoteCurrency = urlParams.get('pool')
        const query = Bitquery.GET_COIN_INFO(tokenAddress, quoteCurrency);

        const opts = {
            body: JSON.stringify({
                query
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-API-KEY": "BQYmjttTNpe1gtI6bCrE8rJ0sWrY5Ej4"
            }

        };

        const response = await fetch(Bitquery.endpoint, opts).then(res => res.json());
        // const coin = response.data.data.ethereum.dexTrades[0].baseCurrency; 
        // console.log(response.data.data.ethereum.dexTrades[0].quotePrice); 
        //console.log(response.data.ethereum.dexTrades[0].baseCurrency); 

        const coin = response.data.ethereum.dexTrades[0].baseCurrency;
        if (!coin) {
            onResolveErrorCallback();
        } else {
            const symbol = {
                ticker: symbolName,
                name: `tkrev.com - ${coin.symbol}/USD`,
                session: '24x7',
                timezone: 'Etc/UTC',
                minmov: 1,
                pricescale: 10000000,
                has_intraday: true,
                intraday_multipliers: ['1', '5', '15', '30', '60'],
                has_empty_bars: true,
                has_weekly_and_monthly: false,
                supported_resolutions: configurationData.supported_resolutions,
                volume_precision: 1,
                data_status: 'streaming',
            }
            //onSymbolResolvedCallback(symbol);
            onSymbolResolvedCallback(symbol)
        }
    },
    // This method is used by the charting library to get historical data for the symbol. 
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback, first) => {
        //set heikin candles
      /*  let sessionTradingViewValuesForHeikinCandles =localStorage.getItem('tradingview.chartproperties.mainSeriesProperties');
        sessionTradingViewValuesForHeikinCandles = sessionTradingViewValuesForHeikinCandles.replace('"style":1', '"style":8');
        localStorage.setItem('tradingview.chartproperties.mainSeriesProperties',sessionTradingViewValuesForHeikinCandles);*/
        //"style":1
        let { from, to, firstDataRequest } = periodParams;
        //console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
        let fromDate = new Date(from * 1000);
        let toDate = new Date(to * 1000);
        if (resolution === '1D') {
            resolution = 1440;
        }
        let fixDateResult = Bitquery.fixDates(fromDate,toDate,resolution,from);
        //console.log(fromToFix)
        fromDate = fixDateResult.fromDate;
        toDate = fixDateResult.toDate;
        from = fixDateResult.from;

        let fromDateString = fromDate.getFullYear() + "-" + ('0' + fromDate.getMonth()).slice(-2) + "-" + ('0' + fromDate.getDate()).slice(-2);
        let toDateString = toDate.getFullYear() + "-" + ('0' + toDate.getMonth()).slice(-2) + "-" + ('0' + toDate.getDate()).slice(-2);

        /*console.log("fromDateString" + fromDateString)
        console.log("toDateString" + toDateString)
        console.log("resolution " + resolution);*/
      
        //url parameters
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const Tokenaddress = urlParams.get('address')
        const quoteCurrency = urlParams.get('pool')

        let query = Bitquery.GET_COIN_BARS(Tokenaddress, fromDateString, toDateString, resolution, quoteCurrency);
        //console.log(query)
        //GET_BNB_BARS
        const opts = {
            body: JSON.stringify({
                query
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-API-KEY": "BQYmjttTNpe1gtI6bCrE8rJ0sWrY5Ej4"
            }

        };
        let responseBNB;
        if(quoteCurrency == "BNB"){
            query = Bitquery.GET_BNB_BARS(fromDateString, toDateString, resolution);
            const BNBopts = {
                body: JSON.stringify({
                    query
                }),
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-API-KEY": "BQYmjttTNpe1gtI6bCrE8rJ0sWrY5Ej4"
                }
    
            };
            responseBNB = await fetch(Bitquery.endpoint, BNBopts).then(res => res.json());
        }
  
        const response2 = await fetch(Bitquery.endpoint, opts).then(res => res.json());
        
      
        //console.log(responseBNB)
        //console.log(response2);
        let bars = [];
        //let barIndex = 0;
        //console.log(new Date(response2.data.ethereum.dexTrades[0].timeInterval.minute).getTime())
        response2.data.ethereum.dexTrades.forEach(el => {
            
            let barsDate = Math.floor(new Date(el.timeInterval.minute).getTime() / 1000.0);

            if (Number(barsDate) >= Number(from) && Number(barsDate) < Number(to) && quoteCurrency == "BNB") {
                const indexOfSameElement = (responseBNB.data.ethereum.dexTrades).findIndex(element => element.timeInterval.minute === el.timeInterval.minute);
                let elBNB = responseBNB.data.ethereum.dexTrades[indexOfSameElement];
                bars = [...bars, {
                    time: new Date(el.timeInterval.minute).getTime(), // date string in api response
                    low: el.low * elBNB.low,
                    high: el.high * elBNB.high,
                    open: Number(el.open) * Number(elBNB.open),
                    close: Number(el.close) * Number(elBNB.close),
                    volume: el.volume
                }]
            }
            if (Number(barsDate) >= Number(from) && Number(barsDate) < Number(to) && quoteCurrency == "BUSD") {
                bars = [...bars, {
                    time: new Date(el.timeInterval.minute).getTime(), // date string in api response
                    low: el.low,
                    high: el.high,
                    open: Number(el.open),
                    close: Number(el.close),
                    volume: el.volume
                }]
            }

            //barIndex++;
        });
        //console.log(bars)
        if (bars.length < 1) {
            onHistoryCallback(bars, { noData: true });
        } else {
            onHistoryCallback(bars, { noData: false });
        }


    },
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeID, onResetCacheNeededCallback) => { },
    unsubscribeBars: (subscribeID) => { }
};
